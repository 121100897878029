@import "custom";
@import "animations";
@import "utilities";
@import "image-slider";

@import "~bootstrap/scss/bootstrap";

@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;700;800;900&display=swap");

@media (min-width: 992px) {
  :root {
    font-size: 16px;
  }
}
html { scroll-behavior: smooth !important; }
body {
  font-weight: 500;
  font-family: "Raleway", sans-serif;
  font-feature-settings: 'pnum' on, 'lnum' on;
}

.btn:active {
  opacity: 0.6 !important;
}

.btn.plp{
  &:hover{
    opacity: 0.8;
  }
}

.btn-disabled {
  background-color: #f8f8f8 !important;
}

.blur {
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  -o-filter: blur(3px);
  -ms-filter: blur(3px);
  filter: blur(3px);
  z-index: 0;
}

.py-2px {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.mb-12px{
  margin-bottom: 12px;
}

.p-12{
  padding: 12px !important;
}

.px-12{
    padding-left: 12px !important;
    padding-right: 12px !important;
}

.py-12{
    padding-top: 12px !important;
    padding-bottom: 12px !important;
}

.pt-12{
  padding-top: 12px !important;
}

.pe-12{
  padding-right: 12px !important;
}

.pb-12{
  padding-bottom: 12px !important;
}

.ps-12{
  padding-left: 12px !important;
}



.text-dark{
  color: #212529 !important;
}

.gap-2{
    column-gap: 8px;
}

.gap-3{
    column-gap: 16px;
}

.no-blur {
  -webkit-filter: blur(0px);
  -moz-filter: blur(0px);
  -o-filter: blur(0px);
  -ms-filter: blur(0px);
  filter: blur(0px);
  z-index: 1;
}

.vh-25 {
  height: 25vh;
}

.vh-20 {
  height: 20vh;
}

a {
  color: #956306;
}

a:hover {
  color: #fec04d;
}

.mbl-nav {
  padding: 0;
  margin: 0;
  list-style: none;
}

.new-mbp-link{
    color: #956306;
    text-decoration: none;

    &:hover{
        color: #583B04 !important;
    }
}

.mbp-mobile-nav a {
  text-decoration: none;
}

.bg-dark {
  background-color: #000000 !important;
}

.bg-dark-blue {
  background-color: #212529 !important;
}
.bg-icy-blue {
  background-color: #95C1E9 !important;
}

.bg-xmas-red {
  background-color: #BD2929 !important;
}

.bg-february-pink {
  background-color: #ED2279 !important;
}

.bg-new-year-deals{
  background-color: #000;
}

.bg-grey {
  background-color: #f8f8f8 !important;
}

.text-secondary {
  color: #6c7074 !important;
}

.bg-primary-pale {
  background-color: #ffdcb6 !important;
}

.border-grey {
  border: 2px solid #777777;
  border-radius: 5px;
}

.strike-through-diagonal {
  transform: rotateZ(30deg);
  position: absolute;
  top: 50%;
  left: -10%;
  width: 120%;
}

.text-bg-height-sm {
  display: inline-block;
  height: auto;
}

.border-bold {
  border: 2px solid grey;
  padding: 0rem 0rem 0rem 0.5rem;
}

.bg-primary-light {
  background-color: $yellow-100;
}

// Start NavBar
.navbar-absolute {
  position: absolute;
  //width: 100%;
}

.nav-link-white {
  font-weight: 500;
  color: #ffffff;
  text-decoration: none;
  border-bottom: 3px solid #fec04d00;
  transition: border-color 250ms ease-in-out;
}

.nav-link-black {
  font-weight: 500;
  //color: #000000;
  text-decoration: none;
  border-bottom: 3px solid #fec04d00;
  transition: border-color 250ms ease-in-out;
}

.nav-link-white:hover,
.nav-link-black:hover {
  //color: #ffffff;
  border-bottom: 3px solid #fec04d;
  transition: border-color 250ms ease-in-out;
}


.nav-link-active {
  font-weight: 700;
  border-bottom: 3px solid #fec04d;
}
// End NavBar

// Start Home
.vh-35 {
  height: 35vh;
}

.vh-60 {
  height: 60vh;
}

.vh-50 {
  height: 50vh;
}

.vh-40 {
  height: 40vh;
}

.vh-30 {
  height: 30vh;
}

.vh-20 {
  height: 20vh;
}

.vh-100 {
  height: 100vh;
}

.text-grey-sm {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #777777;
}

.text-sm {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
}

.text-grey {
  color: #777777;
}

.text-black {
  color: #000000;
}

.text-black-link {
  color: #000000;
  text-decoration: none;
}

.text-black-link:hover {
  color: #000000;
}

.text-black-lg {
  font-style: normal;
  font-size: 54px;
  line-height: 63px;
  color: #000000;
}

.text-white-lg {
  font-style: normal;
  font-size: 54px;
  line-height: 63px;
  color: #ffffff;
}

.text-lg {
  font-style: normal;
  font-size: 54px;
  line-height: 63px;
}

.text-black-md {
  font-style: normal;
  font-size: 24px !important;
  line-height: 30px !important;
  color: #000000;
}

.text-black-sm {
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}

.text-reg {
  font-style: normal;
  font-size: 18px;
  line-height: 25px;
}

.text-20 {
  font-size: 20px;
}

.bike-categories{
  .second-level-filter{
    &:hover{
      background: #FFE9C2 !important;
      border: 1px solid  #FFE9C2 !important;
    }
  }
}

.bikes-tabs{
  .first-level-filter{
    &:hover{
     color: #212529;
    }
  }

}

.text-bold {
  font-weight: 700;
}

.text-md,
.font-md {
  font-weight: 500;
}

// Start Footer
.footer-item a {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #abb0b6;
  font-feature-settings: "pnum" on, "lnum" on;
  text-decoration: none;
}

.footer-item h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}

.footer-label {
  font-style: normal;
  font-weight: 500;
  font-size: 24px !important;
  line-height: 18px;
}

.footer-down p,
.footer-down a {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  color: #ffffff;
  opacity: 0.5;
  text-decoration: none;
}

.footer-link {
  padding: 8px;
}

.footer-link:hover {
  background-color: rgba(255, 255, 255, 0.08);
}

// End Footer

.homebanner-texg-wrap {
  margin-left: 30%;
}

// Start faq It Works
.faq-item span h3 {
  font-weight: 500;
  font-style: normal;
  font-size: 24px;
  line-height: 32px;
}

.faq-item span p {
  font-size: 14px;
  line-height: 120%;
}

.faq-pic {
  width: auto;
  height: 658px;
  position: absolute;
  right: 0;
  display: block;
  margin-top: 10rem;
}

.faq-item {
  padding-left: 12px;
}

// End faq It Works

// Start Details home section
.detail-img-wrap {
  width: auto;
  height: auto;
}

.detail-img {
  width: 100%;
  height: 100%;
}

.detail-wrap h2 {
  font-weight: 700;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
}

.detail-wrap p {
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 20px;
  text-align: center;
}

// End Details home section

// Start Forms
.label-absolute {
  position: absolute;
  width: auto;
  padding: 0rem 0.3rem 0rem 0.3rem;
  margin-top: -6%;
  margin-left: 0.2rem;
}

.transparent-input {
  background-color: rgba(0, 0, 0, 0) !important;
  border: none !important;
}

.transparent-input:focus {
  outline-style: none;
  box-shadow: none;
  border-color: transparent;
}

.bikes-collection > div {
  height: 100%;
}

.text-size-12 {
  font-size: 1.2rem;
}


.text-size-2 {
  font-size: 2rem;
}

.main-logo,
.cursor-pointer,
.pointer {
  cursor: pointer;
}

.force-no-pointer {
  cursor: default;
}

.cursor-normal {
  cursor: default;
}

.slider-thumb {
  width: 20px;
  height: 20px;
  background-color: #FEC04D;
  border-radius: 50%;
  margin-top: 1px;
  &:focus-visible {
    outline: none;
  }
}

.thumb-tooltip {
  margin-bottom: 80px;
  text-align: center;
  color: #fff;
  position: relative;
  filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.2));
  padding: 10px 16px;
  min-width: 73px;
  height: 40px;
  background: #212529;
  border-radius: 16px;
}

.thumb-tooltip:after {
  content: url("../public/assets/icons/arrow-down-sm.svg");
  position: absolute;
  bottom: -10px;
  margin: 0 auto;
}

.slider-thumb-0:focus-visible {
  outline: none;
}

.slider-track,
.double-slider-track {
  height: 0.20rem;
  margin-top: 0.7rem;
  border-radius: 0.25rem;
  background-color: #ebebeb;
}

.slider-track-0 {
  background-color: #fec04d;
  margin-right: 5px;
  height: 0.25rem;
  margin-top: 9px;
  max-width: 96%;
}

.disabled-slider-track{
  height: 4px;
  max-width: 96%;
  margin-top: 1.1rem;
  margin-left: 0.5rem;
  border-radius: 0.25rem;
  background-color: #ebebeb;
}

.slider-track-1 {
  height: 4px;
  margin-top: 10px;
}

.double-slider-track-1 {
  background-color: #fec04d;
  height: 0.25rem;
  margin-top: 10px;
  max-width: 96%;
}

.field-btn-wrap {
  border-color: transparent;
}

// End Forms
//Radio buttons
.container-radio {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  color: #777777;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.container-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.radio {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #ffffff;
  border: 2px solid #777777;
}

.radio-sqre {
  border-radius: 3px;
}

.radio-circ {
  border-radius: 50%;
}

.container-radio:hover input ~ .radio {
  background-color: #ffe3ae;
}

.container-radio input:checked ~ .radio {
  background-color: #fec04d;
}

.radio-sqre:hover {
  background-color: #ffe3ae;
}

.radio-sqre::selection {
  background-color: #fec04d;
}

.radio:after {
  content: "";
  position: absolute;
  display: none;
}

.container-radio input:checked ~ .radio:after {
  display: block;
}

.container-radio .radio-sqre:after {
  top: 25%;
  left: 25%;
  width: 50%;
  height: 50%;
  border-radius: 3px;
  background: white;
}

.container-radio .radio-circ:after {
  top: 25%;
  left: 25%;
  width: 50%;
  height: 50%;
  border-radius: 50%;
  background: white;
}

.bike-details-tab-panel strong {
  font-weight: 700;
  font-size: 1.5rem;
  font-weight: bold;
}

// CSS Animations
.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 100ms ease-in;
}

.fade-leave {
  opacity: 1;
}

.fade-leave.fade-leave-active {
  opacity: 0.01;
  transition: opacity 100ms ease-in;
}

.fade-appear {
  opacity: 0.01;
}

.fade-appear.fade-appear-active {
  opacity: 1;
  transition: opacity 100ms ease-in;
}

.delete-animation-enter {
  opacity: 0;
  transform: translateY(-200px);
}

.delete-animation-enter-active {
  opacity: 1;
  transition: all 400ms ease-out;
  transform: translateY(0px);
}

.delete-animation-exit {
  opacity: 1;
  transform: translateY(0px);
}

.delete-animation-exit-active {
  transform: translateY(-150px);
  opacity: 0;
  transition: all 400ms ease-out;
}

.link-reset,
.link-reset:hover,
.link-reset:focus,
.link-reset:active {
  color: inherit;
  text-decoration: inherit;
}

.text-xs {
  font-size: 0.8rem;
}

.wp-post iframe,
.wp-post img {
  width: 100%;
  object-fit: contain;
}

.image-rounded {
  border-radius: 8px;
}

.react-slideshow-container
+ ul.indicators
.each-slideshow-indicator.active:before {
  background: #ffc107;
  width: 10px;
  height: 10px;
  margin-top: -1.1px;
  left: -1px;
}

.react-slideshow-container + ul.indicators li {
  width: 12px !important;
}

.bg-orangeLight {
  background-color: rgba(254, 192, 77, 0.2);
}

.search-field:focus {
  outline-color: #fec04d;
}
.brandworld{
  border:.5px solid white;
&::placeholder {
  color: white;
  opacity: .6; 
}}
.z-index {
  z-index: 5;
}

.plan-length-label-container {
  margin-right: -5px;
}

.table-border-top {
  border-top: 2px solid;
}

.block-image-text-no-padding + .block-blog {
  margin-top: 120px !important;
}

@media print {
  .print-small-fontsize {
    font-size: 0.6em !important;
  }
}

input[type="date"] {
  background-color: transparent;
  outline: none;
  color: #000000;
}


.bikes-slider-wrapper .slick-slide.slick-active,
.review-slider .slick-slide.slick-active {
  max-width: 305px !important;
}

.blogs-slider-wrapper .slick-slide.slick-active {
  max-width: 312px !important;
}

.review-slider ul {
  padding-left: 0;
}

.review-slider .slick-dots li {
  margin: 0;
  width: 15px;
}

.review-slider .slick-dots li button:before {
  opacity: 1;
}

.review-slider .slick-dots {
  li.slick-active button:before {
    color: #fec04d;
  }
}

.bike-types::-webkit-scrollbar {
  display: none;
}

.bikes-tabs{
    .bike-tab {
        height: 100%;
        color: #6C7074;
        border: 1px solid #DEE2E6;
        cursor: pointer;

        &:hover {
            color: #212529 !important;
        }

        &:first-child{
            border-radius: 4px 0px 0px 4px;
        }

        &:last-child{
            border-radius: 0px 4px 4px 0px;
            border-left: none;
        }

        &:not(:first-child){
            border-left: none !important;
        }
    }

    .bike-tab-active{
        border: 1px solid #FFE9C2;
        color: #212529;
        background-color: #FFE9C2;
    }

}
.gradient-purple-transparent {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgb(0, 0, 0);
  background: linear-gradient(0deg, rgba(46, 4, 62, 1) 0%, rgba(46, 4, 62,0) 100%);
  opacity: 0.75;
  border-radius: 16px;
}

.gradient-black-transparent {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgb(0, 0, 0);
  background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
  opacity: 0.75;
}

.gradient-black-transparent.top-down {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
}

.bg-dark-transparent {
  background-color: rgba(0, 0, 0, 0.85);
}

.bg-light-dark-transparent {
  background-color: rgba(0, 0, 0, 0.64);
}

.dots-wrapper {
  height: 100%;
  width: 100%;

  .solid-lines {
    height: calc(100% - 90px);
    margin: 0 auto;
    width: 2px;
    background-image: linear-gradient(#212529 100%, rgba(255, 255, 255, 0) 0%);
    background-size: 2px 11px;
    background-repeat: repeat-y;
  }
}

.ask-question-btn:hover {
  background: #212529;
  color: #fff !important;
}

.read-more-btn,
.height-link {
  transition: 0.2s ease;

  &:hover {
    background: #eff1f3 !important;
  }

  &:active {
    background: #dce1e7 !important;
  }
}

.bike-size-btn {
  &:after {
    content: url("../public/assets/icons/arrow-down-sm.svg");
    position: absolute;
    bottom: -9px;
    margin: 0 auto;
    left: 27px;
    z-index: 100;
    scale: 1.7;
  }
}

.comparison-btn-mobile {
  height: 32px;
  padding-left: 6px;
  border-radius: 4px;
  cursor: pointer;
}

.comparison-btn-wrapper:after {
  content: url("../public/assets/icons/arrow-up-grey-sm.svg");
  position: absolute;
  top: -16px;
  left: 16px;
}

.filter-modal {
  position: relative;

  ::-webkit-scrollbar {
    width: 4px;
    padding-right: 10px;
  }

  ::-webkit-scrollbar-track {
    margin-top: 30px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #e4e4e4;
    border-radius: 3px;
  }
}

.price-filter-slider {
  input {
    padding-left: 12px;
    width: 45%;
    height: 40px;
    background: #ffffff;
    border: 1px solid #d2d7dc;
    border-radius: 4px;
    font-feature-settings: 'pnum' on, 'lnum' on;

    &:focus-visible {
      outline: none;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}

.pdp-bikes-slider {
  .slick-slide.slick-active {
    max-width: 296px !important;
  }

  .bike-item {
    max-width: 288px !important;
  }
}

.home-banner-height {
  min-height: 362px;
}

.bg-inherit-grey {
  background-color: inherit;
}

.bg-grey-inherit {
  background-color: #f8f8f8;
}

.bg-light-inherit {
  background-color: #f3f2f2;
}

.search-results {
  height: 80vh;
  z-index: 1;
  overflow-y: scroll;
  overflow-x: hidden;
}

.book-appointment {
  width: 100%;
}

.newsletter-modal {
  max-width: 91vw;
}

.cart-compatibility-modal {
  max-width: 91vw;
  max-height: 85vh;
}

.cart-compatibility-modal-section {
  max-height: 170px;
}

.collection-grid-view-item {
  transform: none;
  box-shadow: none;
}
.home-banner-slider.darker {
  .slick-dots{
    li {

      background-color: #212529!important;
    }}
}

.slick-dots ul{
    padding-left: 0rem !important;
}

.home-banner-slider {
  .slick-dots{
    li {
      width: 8px;
      height: 4px;
      border-radius: 4px;
      background-color: #FFFFFF !important;
      button{
        opacity:0;
      }
      button:before {
        opacity: 0;
      }
    }
    li.slick-active {
      width: 16px;
      background-color: #fec04d !important;
      transition:all .5s linear;
      button:before {
        color: #fec04d;
        opacity: 1;
      }
    }
  }

  .rounded-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
  }
}

.ai-suggestions-slider {
  .slick-dots{
    li {
      width: 8px;
      height: 4px;
      border-radius: 4px;
      background-color: #000 !important;
      button{
        opacity:0;
      }
      button:before {
        opacity: 0;
      }
    }
    li.slick-active {
      width: 16px;
      background-color: #fec04d !important;
      transition:all .5s linear;
      button:before {
        color: #fec04d;
        opacity: 1;
      }
    }
  }
.slick-track{
  display: flex !important;
  align-items: center;
  justify-content: center;
}
  .rounded-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
  }
}

.delivery-and-returns-sidebar-height {
  min-height: auto;
}

.delivery-and-returns-sidebar-width {
  width: 100vw;
  max-width: 480px;
}

.comparison-plp-fixed-component {
  border-top: solid;
  border-top-width: 2px;
  border-top-color: #dee2e6;
}

.text-black-md-white {
  color: #000000;
}

.blog-post-book-consultation-sizes {
  min-width: 64px;
  min-height: 64px;
  max-height: 110px;
}

.header-banner-title {
  z-index: 10;
  top: 40%;
}

.header-banner-img {
  min-height: 167px;
}

.custom-dots {
  margin-bottom: 20px;
}

.custom-slider-mobile-pdp{
    .custom-dots {
        z-index: 10;
        margin-bottom: 60px !important;
    }
}

.custom-slider-mobile{
    .custom-dots {
        z-index: 10;
        margin-bottom: 3px !important;
    }
}

.card-item {
  transition:all 0.4s linear !important;
  &:active,
  .card-item-price-section:active {
    background-color: #dce1e7 !important;
  }

  .slick-track {
    padding-top: 0 !important;
  }
}

.card-shadow {
  transition:all 0.2s linear ;
  -webkit-box-shadow: 0px 8px 24px 0px rgba(33, 37, 41, 0.12), 0px 8px 8px 0px rgba(33, 37, 41, 0.04);
  box-shadow: 0px 8px 24px 0px rgba(33, 37, 41, 0.12), 0px 8px 8px 0px rgba(33, 37, 41, 0.04);
}

.card-item:hover {

  background-color: #dce1e7;
}

.home-banner-slider {
  .gradient-black-transparent,
  img {
    border-radius: 16px 16px 16px 16px;
  }

  .rounded-circle {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    border-radius: 4px !important;
    background: rgba(255, 255, 255, 0.16) !important;
    margin: 0 62px;
  }
  .rounded-circle-new {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    background: rgba(255, 255, 255, 0.16) !important;
    margin: 0 62px;
  }
}

.accordion .rounded-circle{
  border-radius: 50% !important;
}

.slick-slider{
  .rounded-circle {
    display: flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    background-color: rgba(33, 37, 41, 0.80) ;
    transition: background-color 0.2s ease-in-out;

    &:hover {
      background-color: rgba(33, 37, 41, 0.80) ;
    }

    &:active {
      background-color: rgba(33, 37, 41, 0.80) ;
    }
  }
}

.custom-dots{
    margin-bottom: 10px;
    li {
        width: 8px;
        height: 4px;
        border-radius: 4px;
        background-color: #212529 !important;
        button{
        color: #212529 !important;
        }
        button:before {
        background-color: #212529;
        opacity: 1;
        }
    }
    li.slick-active {
        width: 16px;
        background-color: #fec04d !important;
        height: 4px;
        button:before {
        color: #fec04d;
        opacity: 1;
        }
    }
}

.similar-bikes, .main-slider-pdp {
  .rounded-circle {
    display: flex;
    width: 32px;
    height: 32px;
    justify-content: center;
    align-items: center;
    background-color: rgba(33, 37, 41, 0.80) !important;
    transform: translateY(-50%) translateX(35%) !important;
    transition: background-color 0.2s ease-in-out;

    &:hover {
      background-color: rgba(33, 37, 41, 0.80) !important;
    }

    &:active {
      background-color: rgba(33, 37, 41, 0.80) !important;
    }

    &:nth-child(1) {
      transform: translateY(-50%) translateX(-35%) !important;
    }
  }
}

.recommended-bikes {
  .slick-dots{
    li {
      width: 8px;
      height: 4px;
      border-radius: 4px;
      background-color: #212529 !important;
      button{
        opacity:0;
      }
      button:before {
        opacity: 0;
      }
    }
    li.slick-active {
      width: 16px;
      background-color: #fec04d !important;
      transition:all .5s linear;
      button:before {
        color: #fec04d;
        opacity: 1;
      }
    }
  }

  & > div{
    padding: 0;
  }


}
.explorer-tab-arrows{
  padding:8px;
  background-color:  white !important;
}

.explorer-tab-arrows:hover{
  background-color:  #EFF1F3 !important;
}

.explorer-tab-arrows:active{
  background-color:  #DCE1E7 !important;
}

.concept-wrapper{
    padding: 16px;
  }

.whatsapp-slide-text {
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  color: #fff;
  height: 350px;
}

li.slick-active {
  button:before {
    color: #fec04d !important;
    opacity: 1;
  }
}

.sticky-filters{
  position: sticky;
  top: 150px;
  height: 100vh;
  padding-bottom: 30vh;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 4px;
    padding-right: 10px;
  }

  &::-webkit-scrollbar-track {
    margin-top: 30px;
  }

  &::-webkit-scrollbar-thumb {
    background: #e0dfdf;
    border-radius: 3px;
  }
}

.dots-bg-dark{
  .slick-dots{
    ul{
      padding-left: 0;
      li{
        width: 7px;
        button:before{
          display: flex;
          justify-content: center;
          align-items: center;
          color: #212529;
          opacity: 1;
          width: 6px;
          height: 6px;
        }
      }
    }
  }
}

.filter-item-box {
  &:hover {
    background-color: #FFF2DB;
  }
  &:active {
    background-color: #FFF2DB;
  }
}
.compare-btn,
.comparison-btn-mobile {
  position: absolute;
  z-index:1001;
  right:10px;
  width: 24px;
  height: 24px;
  top:3rem;
  transform: translateY(-50%);
  @media screen and (max-width: 768px) {
    right:0px;
    top:55px;
  }
}

.compare-btn-list-view,
.comparison-btn-mobile-list-view {
  position: absolute;
  z-index:1001;
  right:10px;
  width: 24px;
  height: 24px;
  top:3rem;
  transform: translateY(-50%);
  @media screen and (max-width: 768px) {
    right:6px;
    top:70px;
  }
}

.compatible-accessories-btn-bookmark {
  position: absolute;
  z-index:1001;
  right: -5px;
  width: 24px;
  height: 24px;
  top:1rem;
  transform: translateY(-50%);

}
.comparison-btn-accessory {
    position: absolute;
    z-index:1001;
    right: 0px;
    width: 24px;
    height: 24px;
    top:3rem;
    transform: translateY(-50%);

}
.compare-btn-not-absolute,
.comparison-btn-mobile-no-tabsolute {
  &:hover {
    background-color: #eff1f3;
  }

  &:active {
    background-color: #dce1e7;
  }
}

.backdrop {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(33, 37, 41, 0.24);
  backdrop-filter: blur(12px);
  z-index: 1;
}

.backdrop-simple {
  backdrop-filter: blur(12px);
}

.height-link {
  &:hover {
    background-color: #eff1f3;
    border-radius: 4px;
  }
}

.grey-hover{
  &:hover{
    background-color: #EFF1F3;
  }
  &:active{
    background-color: #DCE1E7;
  }
}

.partners-slider{
  .slick-track{
    display: flex;
    align-items: center;
  }
}


.add-bike-linear-gradient {
  position: relative;
  display: inline-block;
  font-size: 30px;
}

.add-bike-linear-gradient::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 4px;
  padding: 2px;
  background: linear-gradient(
                  to right bottom,
                  #DF14D7,
                  #AE68F4,
                  #6284F4,
                  #15C6E0,
                  #14C1CB,
                  #89DBB6,
                  #E55B0C,
                  #E3C26A
  );
  /* The following lines create the gradient mask effect & wont work without this code */
  -webkit-mask: linear-gradient(#fff 0 0) content-box,
  linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;

  pointer-events: none;
}

.overview-plan{
  .slider-track-0{
    background-color: #212529;
  }
}

.discount-code-input{
  outline: none;
}

.disabled-bracket{
    color: #6c7074 !important;
}

.downpayment-bracket {
    width: 79px;
    height: 52px;
}

.downpayment-input-wrapper{
  border-radius: 4px;
  border: 1px solid  #D2D7DC;
  background: #FFF;
  padding: 8px;
  font-size: 14px;
}

.downpayment-input{
  display: flex;
  align-items: center;
  outline: none;
  width: 100%;
  border: none;
  font-feature-settings: 'pnum' on, 'lnum' on;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
}

.my-plan-wrapper{
  padding: 16px 12px;
  border-radius: 16px;
  background: var(--background-light-grey, #F8F8F8);
}

.credit-check-tile{
    position: relative;

  &::after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 53.5%,
     rgba(0, 0, 0, 0.08) 56%,
     rgba(0, 0, 0, 0.16) 58.5%,
     rgba(0, 0, 0, 0.22) 60.5%,
     rgba(0, 0, 0, 0.32) 63%,
     rgba(0, 0, 0, 0.42) 66%,
     rgba(0, 0, 0, 0.52) 69%,
     rgba(0, 0, 0, 0.62) 73.5%,
     rgba(0, 0, 0, 0.72) 78.5%,
     rgba(0, 0, 0, 0.82) 82.5%);
  }

  .info-card-text{
    z-index: 10;
  }
}


.find-perfect-bike{
  .info-card{
    min-height: 384px;
    height: 100%;
    border-radius:32px;
  }
}

.leadmagnet-card-btn{
    cursor: pointer;
    border-radius: 4px;
    transition: opacity 0.3s ease;
    &:hover{
        opacity: 0.8;
    }
}

.info-card-image{
  width: 100%;
  height: 192px;
  border-radius:16px;
  position: relative;

  &::after{
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    border-radius:16px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.15) 53.5%, rgba(0, 0, 0, 0.16) 56%, rgba(0, 0, 0, 0.16) 58.5%, rgba(0, 0, 0, 0.22) 60.5%, rgba(0, 0, 0, 0.32) 63%, rgba(0, 0, 0, 0.42) 66%, rgba(0, 0, 0, 0.52) 69%, rgba(0, 0, 0, 0.62) 73.5%, rgba(0, 0, 0, 0.72) 78.5%, rgba(0, 0, 0, 0.82) 82.5%);
  }

  img{
    border-radius:16px;
  }
  .info-card-title{
    left: 24px;
    bottom: 12px;
    line-height: 36px;
    font-size: 28px;
    z-index: 2;
  }
}

.text-container {
  max-height: 4.5rem;
  overflow: hidden;
}
.multiline-truncate-desc {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.4rem; 
  max-height: calc(1.4rem * 4); 
  padding-bottom: 0;
}

.productinfo-bike-desc p{
    margin-bottom: 1.5rem;
    line-height: 20px;
}

.multiline-truncate {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  margin: 0;
}
.indent-p {
  text-indent: 0;
  padding-right: 4rem;
}
.read-more-link {
  position: absolute;
  bottom: 0;
  right: 0;
}

.colors-sizes-wrapper, .duration-and-downpayment{
  border: 1px solid #dee2e6;
}

.search-content{
  position: absolute;
  min-height: 556px;
  border-radius: 0px 0px 32px 32px;
}

.downpayment-price-tooltip{
  background-color: #EC0000;
  border-radius: 16px;
  padding: 12px 16px;

  &::after {
    content: url("../public/assets/icons/chevron-down-red.svg");
    position: absolute;
    bottom: -18px;
    left: 42px;

    @media(max-width: 768px){
      scale: 0.7;
      bottom: -16px;
      left: 32px;
    }
  }
}

.grid-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}

.grid-cols-2 {
  display: grid;
  column-gap: 1rem;
  row-gap: 1rem;
  grid-template-columns: repeat(2, 1fr);

  @media screen and (min-width: 768px) and (max-width: 992px) {
    grid-template-columns: 1fr;
  }
}

.brands-page-text{
  font-size:36px !important;
  line-height: 44px !important;
}

.brands-block-section{
width:100%;
background-color: #F8F8F8;
padding:16px;
border-radius: 4px;
}

.swiss-badge-position{
  position:absolute;
  top:-20px;
  left:50%;
  transform: translateX(-50%);
}

.swiss-badge-position-ikea-design{
  position:absolute;
  top:-2px;
  left:50%;
  transform: translateX(-50%);
}

.swiss-badge-position-mobile{
  z-index:1000;
  position:absolute;
  background-color: transparent;
  right:55%;
  top:0.95rem;
  transform: translateY(-50%);
}
.bookmark{
  position: absolute;
  z-index:1001;
  right: 4px;
  width: 24px;
  height: 24px;
  top:1rem;
  transform: translateY(-50%);
}

.bookmark-list-view{
  position: absolute;
  z-index:1001;
  right: 4px;
  width: 24px;
  height: 24px;
  top:1rem;
  transform: translateY(-50%);
}

.bookmark-compatible-accessories{
  position: absolute;
  z-index:1001;
  right: 0px;
  width: 24px;
  height: 24px;
  top:0rem;
  transform: translateY(-50%);
}
.wishlist-container {
  margin:auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, 300px);
  column-gap: 24px,;
  row-gap: 2rem;
}

.wishlist-container-mobile {
  display: grid;
  column-gap: 0.5rem;
  row-gap: 1.5rem;
  padding: 6px;
  grid-template-columns: repeat(auto-fill, minmax(163px, 1fr)); /* Adjust column size as needed */

    &:nth-child(3) {
    grid-column: span 2;
    }
}
.ebook-container{
   background-color: #F8F8F8;
   border-radius: 32px;
}

.ebook-hover-content{
  background-color: #212529E0;
  border-radius: 16px;
  width: 217px;
  height: 323px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
}

.miniPLP-container {
  display: grid;
  margin-top: 16px;
  gap:1px;
  justify-content: center;
  align-items: flex-start;
  grid-template-columns: repeat(auto-fit,216px);
}

.span-up-2-grid-columns{
  grid-column: span 2;
}

.transparent-black-background{
  background: rgb(0, 0, 0);
  background: linear-gradient(0deg, rgba(0, 0, 0, 1) 10%, rgba(0, 0, 0, 0) 100%);
  }

  .toast-message-container{
    min-width:328px;
    border-radius: 4px;
    min-height:40px;
    padding:8px;
    display: flex;
    justify-content:flex-start;
    align-items: center;
    column-gap: 8px;
    position:fixed;
    top:20%;
    right:0%;
    z-index: 99999999;
    background-color: white;
  }

.service-search-field:focus {
  outline-color: #fec04d;
}
.span-up-2-grid-columns{
  grid-column: span 2;
}

.transparent-black-background{
  background: rgb(0, 0, 0);
  background: linear-gradient(0deg, rgba(0, 0, 0, 1) 10%, rgba(0, 0, 0, 0) 100%);
  }

  .toast-message-container{
    min-width:328px;
    border-radius: 4px;
    min-height:40px;
    padding:8px;
    display: flex;
    justify-content:flex-start;
    align-items: center;
    column-gap: 8px;
    position:fixed;
    top:20%;
    right:0%;
    z-index: 99999999;
    background-color: white;
  }


.sponsor-me-banner{
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  z-index: -1;
  background-color: #f3f2f2;
  margin-bottom: -15px;
  border-bottom: none;
  padding:12px  16px 24px 16px;
}

.pdp-add-wishlist-button:hover{
opacity:0.8;
}

.pdp-add-wishlist-button:active{
  opacity:0.6;
  }

.recommendation-tooltip-tag {
  position: relative;
  display: inline-block;
}

.recommendation-tooltip-tag .recommendation-tooltip-tagtext {
  visibility: hidden;
  background-color: #212529;
  color: #fff;
  text-align: center;
  padding: 4px 8px;
  border-radius: 6px;
white-space: nowrap;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
transform:translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.3s;
}

.recommendation-tooltip-tag .recommendation-tooltip-tagtext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #212529 transparent transparent transparent;
}

.recommendation-tooltip-tag:hover .recommendation-tooltip-tagtext {
  visibility: visible;
  opacity: 1;
}

.free-service:hover{
  background: #EFF1F3;
}


.switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 24px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}


.switch-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #EBEBEB;
  -webkit-transition: .4s;
  transition: .4s;
}

.switch-slider:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: 2px;
  bottom: 1px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .switch-slider {
  background-color: #FEC04D;
}

input:focus + .switch-slider {
  box-shadow: 0 0 1px #FEC04D;
}

input:checked + .switch-slider:before {
  -webkit-transform: translateX(22px);
  -ms-transform: translateX(22px);
  transform: translateX(22px);
}

.switch-slider.round {
  border-radius: 34px;
}

.switch-slider.round:before {
  border-radius: 50%;
}

.social-proof-tag {
  position: relative;
  display: inline-block;
  overflow: visible;
}

.social-proof-tag .social-proof-tagtext {
  visibility: hidden;
  background-color: #212529;
  color: #fff;
  text-align: left;
  padding: 4px 8px;
  border-radius: 6px;
  position: absolute;
  min-width: 360px;
  z-index: 9999;
  margin-top: 2rem;
  transform:translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
}

.social-proof-tag .social-proof-tagtext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 52.5%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color:  transparent transparent #212529 transparent ;
}

 .social-proof-tagtext.active {
  visibility: visible;
  opacity: 1;
}

.hero-section{
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  display: grid;
  grid-template-columns: 65% 28%;
  align-items:flex-start;
  flex-wrap: wrap;
  column-gap: 1rem;
  row-gap: 1rem;
  justify-content:center;
  margin:auto;
  transform:translateY(5%);
  margin-bottom: 3rem;
  max-width: 1890px;
}
.home-banner-width{
  width: 100%;
}

 .selected-size-variation:hover{
   background-color: #EFF1F3;
   transition: 0.2s ease;
 }

 .variation-item{
   border-bottom: 1px solid transparent;
 }

 .border-active{
    border: 1px solid #FEC04D;
 }

 .size-tooltip{
   opacity: 0;
   visibility: hidden;
   background-color: #212529;
   color: #fff;
   text-align: left;
   padding: 4px 8px;
   border-radius: 6px;
   position: absolute;
   width: 216px;
   z-index: 9999;
   transition: opacity 0.3s;
   bottom: 27px;
   left: 10px;
 }

.size-tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 52.5%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #212529 transparent;
  transform: rotate(180deg);
}

.size-tooltip.active {
  visibility: visible;
  opacity: 1;
}

.banner-with-form{
  background-color: rgba(0, 0, 0, 0.5);
  min-height: 603px;
  width: 100%;
  border-radius: 32px;

  &::after{
    content: "";
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    top:0;
    left:0;
    border-radius: 32px;
    z-index: 1;
  }
}


.banner-with-form, .banner-form-mobile{
  .text-section{
    z-index:2;
    .instruction-steps {
      .step {
        display: flex;
        align-items: center;
        margin-bottom: 2rem;
        position: relative;

        &:last-child{
            margin-bottom: 0rem;
        }

        &:not(:last-child)::after {
          content: "";
          position: absolute;
          left: 20px;
          top: 40px;
          width: 2px;
          height: 4.5rem;
          @media (max-width: 768px) {
            height: 3.5rem;
          }
          background-color: #FEC04D;
        }

        .number {
          background-color: #FEC04D;
          padding: 0.5rem 1rem;
          border-radius: 4px;
          margin-right: 1rem;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        p {
          color: white;
          font-size: 1.25rem;
          margin: 0;
        }
      }
    }
  }

  .form-section{
    z-index:2;
    height: 100%;
    max-width: 517px;
    width: 100%;
    border-radius: 8px;
    background-color: #F4F4F4;
  }
}

.similar-bikes-container-padding{
  padding:40px 0px 20px;
}

.similar-bikes-child-container-padding{
  padding: 0px 40px;
}
.similar-bikes-modal-content{
  display: grid;
  margin-top: 16px;
  padding-inline: 8px;
  justify-content: center;
  align-items: flex-start;
}

.miniPLP-container{
  gap:1px;
  display: grid;
  margin-top: 16px;
  justify-content: center;
  align-items: flex-start;
  grid-template-columns: repeat(auto-fit, minmax(194px, 1fr));
}

.miniPLP-container{
  gap:1px;
  display: grid;
  margin-top: 16px;
  justify-content: center;
  align-items: flex-start;
  grid-template-columns: repeat(auto-fit, minmax(194px, 1fr));
}

.bikes-collection{
    display: grid;
    margin-top: 16px;
    gap: 1px;
    align-items: flex-start;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

.active-card-item-border{
  outline:1px solid #2CA58D;
}
.featured-card-item-border{
  outline:1px solid #FEC04D;
}
.normal-card-item-border{
  outline:1px solid #DEE2E6;
}

.rounded-bottom-left{
border-bottom-left-radius: 16px !important;
}


.rounded-bottom-right{
  border-bottom-right-radius: 16px !important;
  }


.rounded-top-left{
  border-top-left-radius: 16px !important;
  }


.rounded-top-right{
  border-top-right-radius: 16px !important;
  }


.hide-overflow-scrollbar {

    -ms-overflow-style: none;  /* Internet Explorer and Edge */
    scrollbar-width: none;  /* Firefox */


  &::-webkit-scrollbar {
    display: none;  /* WebKit browsers */
  }
}

.stories-section{
    background: #F8F8F8;
}

.link-hover{

  &:hover{
    transition: all 0.4s linear;
    background-color: #f3f2f2;
  }
}

.link-hover{

  &:hover{
    transition: all 0.4s linear;
    background-color: #f3f2f2;
  }
}


.competition-comfirmation{
    .competition-text{
        max-width: 628px;
        width: 100%;
    }
  }


.previous-filters-border{
  border:none;
}


.brand-description-truncate{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.story-thumnail{
  min-height: 72px;
  min-width: 72px;
  border-radius: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor:pointer;
  transition: all 0.2s linear;
  &:hover{
    transform:scale(105%)
  }
  }

  .seen-borders{
    border:3px solid #DEE2E6;
  }

  .not-seen-borders{
    border:3px solid #FEC04D;
  }





.video {
  height: 100%;
  width: 177.77777778dvh;
  min-width: 100%;
  min-height: 56.25dvw;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}



.slider-tooltips{
    .tooltip-content {
        top: 24px;
        left: 18px;
        width: 400px;
        border-radius: 4px;
    }
}


.service-centers-filters{
    column-gap: 24px;
}

.brand-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    li {
      flex: 1 1 45%; /* Adjust this value if needed */
    }

    &::-webkit-scrollbar {
      width: 4px;
      margin-right: 10px;
    }

    &::-webkit-scrollbar-track {
      margin-top: 30px;
    }

    &::-webkit-scrollbar-thumb {
      background: #e0dfdf;
      border-radius: 3px;
    }
  }


.brand-list li {
  cursor: pointer;
}

.hide-scrollbar {
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.hide-scrollbar::-webkit-scrollbar {
    display: none;
}

.bike-explorer-tabs {
    .recently-viewed-inactive,
    .recommendations-inactive {
        border: 1px solid #DEE2E6;
        .tab-content {
            opacity: 0.4;
        }
    }

    .recently-viewed-inactive {
        border-left: none;
    }

    .recommendations-inactive {
        border-right: none;
    }

    .recently-viewed-active,
    .recommendations-active {
        background-color: #FFE9C2;
        border: 1px solid #FFE9C2;
        .tab-content {
            opacity: 1;
        }
    }

    .tab {
        &:first-child {
            border-radius: 4px 0px 0px 4px;
        }

        &:last-child {
            border-radius: 0px 4px 4px 0px;
        }

        .tab-content {
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }
    }


}

.bike-explorer-tabs-content{
    overflow-y: scroll;
    height: 50vh;
    overflow-x: hidden;
    width: 100%;
    padding-right: 8px;


    &::-webkit-scrollbar {
        width: 4px;
        margin-right: 10px;
    }

    &::-webkit-scrollbar-track {
        margin-top: 30px;
    }

    &::-webkit-scrollbar-thumb {
        background: #e0dfdf;
        border-radius: 3px;
    }
}

.leadmagnet-card-btn{
    cursor: pointer;
    border-radius: 4px;
    transition: opacity 0.3s ease;
    &:hover{
        opacity: 0.8;
    }
}

.leadmagnets-modal-content{
    padding: 40px;
}

.nav-tabs{
  z-index: 1000000;
  border-top: 1px solid #DEE2E6;
  box-shadow: 0px -1px 20px 0px #00000014;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: space-between;
  position:fixed;
  bottom:0px;
  left: 0px;
  right: 0px;
  width: 100%;
  background-color: #FFFFFF;
  .tab-link{
    transition: all 0.4s linear ;
    border-radius: 4px;
    flex:1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 8px;
    row-gap: 4px;

    &.active{
      background-color: #FFE9C2;
    }
  }
}
.top-shadow {
  box-shadow: 0px -4px 8px -4px rgba(0, 0, 0, 0.1);
}

.mobile-search-results{
  padding: 6px ;
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: repeat(2,minmax(165px,1fr));
}
.ai-response-pdp {
  border-radius:  0px  0px 16px 16px;
  background: #F8F8F8;
  padding: 8px 24px;
  margin-top: -.7rem;
  z-index: 1;
  position: relative;
}
.send-message{
  width: 100%;
  background-color: white;
  border-radius: 4px;
  z-index:1009;
  border:1px solid #DDDDE3;
  display: flex;
  position:relative;
  align-items: center;
  justify-content: space-between;
  .input-field{
flex:1;
border:none;
outline: none;
padding-inline: .5rem;
z-index:10;
resize: none;
  }
}
.loading-dots {
  display: flex;
  justify-content: flex-start;
  column-gap:6px;
}

.loading-dot {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #333;
  animation: dot-bounce 1.2s infinite ease-in-out;
}

.loading-dot:nth-child(1) {
  animation-delay: 0s;
}

.loading-dot:nth-child(2) {
  animation-delay: 0.2s;
}

.loading-dot:nth-child(3) {
  animation-delay: 0.4s;
}
.generated-prompt{
  display:flex !important;
  max-width: 75dvw;
  border:1px solid transparent;
  align-items:flex-start;
  justify-content: center;
  background-color: #F5F5F5;
  border-radius: 9px;
  padding:1rem;
  flex-direction: column;
  gap:.5rem;
  transition: all 0.5s linear;
  cursor:pointer;
  &:hover{
    background-color: #e4e4e4;
  }
}
.carousel-ai-card{
  background-color: #f8f8f8;
  border-radius: 32px;
 height: fit-content;
  @media screen and(max-width:768px){
&{
  max-width: 85dvw;
}
  }
}


.ai-explanation{
  &>.ai-response{
    position:absolute;
    top:0;
    transform:translateY(100%);
    padding:0.5rem 1.2rem;
    border-radius:.5rem;
  }
}
.generated-prompt.active{
  border:1px solid #ffbf4d;
}

.try-ai {
  background: rgb(162,25,212);
background: -moz-radial-gradient(circle, rgba(162,25,212,1) 31%, rgba(255,255,255,1) 68%, rgba(162,25,212,1) 97%);
background: -webkit-radial-gradient(circle, rgba(162,25,212,1) 31%, rgba(255,255,255,1) 68%, rgba(162,25,212,1) 97%);
background: radial-gradient(circle, rgba(162,25,212,1) 31%, rgba(255,255,255,1) 68%, rgba(162,25,212,1) 97%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#a219d4",endColorstr="#a219d4",GradientType=1);
  animation: gradient-change 7s ease infinite;
  background-size: 300% 300%;

}

.shadow-top{
  box-shadow: 0 -8px 15px #A219D4;
}

.shadow-left{
    box-shadow: -10px 0px 20px #A219D4;
}

.ai-modal::after {
  content: "";
  position: absolute;
  bottom: 10dvh;
  border:transparent;
  z-index: 10;
  animation: translateAnimation 8s ease-in-out infinite;
  width: 15rem;
  height: 15rem;
  background-image: url('/assets/icons/gradient-circle-1.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.ai-modal::before {
  content: "";
  position: absolute;
  bottom: 10dvh;
  border:transparent;
  z-index: 10;
  animation: translateAnimationBackwardsDirection 8s ease-in-out infinite;
  width: 15rem;
  height: 15rem;
  background-image: url('/assets/icons/gradient-circle-light.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.sizes-colors-wrapper{
    border-radius: 16px;
    border: 1px solid #DEE2E6;
}
.search-ai-explanation{
  display: grid;
  gap: 1rem;
  align-content: center;
  grid-template-columns: repeat(auto-fit,minmax(343px,1fr));
  @media screen and (max-width:768px) {
    grid-template-columns: 1fr;
gap:2rem;
  }
}


.search-ai-prompts-on-landing-page{
  display: grid;
  gap: 1rem;
  align-content: center;
  grid-template-columns: repeat(auto-fit,minmax(453px,1fr));
  @media screen and (max-width:768px) {
    display: none;
  }
}

.search-ai-results-on-landing-page{
  display: grid;
  gap: 1rem;
  align-content: center;
  max-width: 100%;
  grid-template-columns: repeat(auto-fit,minmax(412px,1fr));
  @media screen and (max-width:768px) {
    display: none;
  }
}
.story-like:active{
  transform: scale(85%);
  transition: all 0.1s linear;
}
.pdp-link{
  padding: .6rem 1.5rem;
  cursor: pointer;
  transition: all 0.4s linear;
  border: 3px solid transparent;
  &:hover{
  border-bottom: 3px solid #fec04d;

}}

.pdp-link.active{
  border-bottom: 3px solid #fec04d;
}
.plp-sticky-bar-shadow{
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.plp-navigation-bar{
  top: 24%;
	z-index: 100000;

  @media screen and (max-width:991px) {
    &{
      top: 0;
    }
  }
}
.plp-navigation-bar.scrolling-down{
  top: 23.4%;
}

.plp-navigation-bar.scrolling-up{
  @media screen and (max-width:1271px) {
    &{
      top: 29%;
      z-index: 1000;
    }
  }
}
.pdp-breacrumbs{
    span{
        color: #956306;
        line-height: 16px;
        cursor: pointer;
    }
}

.bike-overview {
    h2{
        font-size: 14px;
    }

    ul{
        padding-left: 0px;
        margin-top: 1rem;

        .custom-list-item {
            display: flex;
            align-items: flex-start;
            margin-bottom: 8px;
            column-gap: 16px;
          }
    }
}

.zoom-in{
    cursor: -webkit-zoom-in;
}

.fullscreen-slider-close-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #373434;
    border-radius: 4px;
    position: absolute;
    top: 10px;
    right: 10px;
    opacity: 0.8;
    width: 50px;
    height: 50px;
    border: none;

    &:hover{
        background-color: #2c2d2e;
    }
}

.fullscreen-slider-image {
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 55vw;
    height: 100vh;
    overflow: hidden;
}

.predefined-ai-question{
  background-color: #F2DAFB;
  border-radius: 4px;
  flex-shrink: 0;
  padding:  .25rem 0.5rem;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.4s linear;
  &:hover{
    opacity: 0.7;
  }
}
.z-index-15{
  z-index:15;
}
.brand-world-hero{
  margin-top: 142px;
}

.brand-world-categories{
   min-height: 585px;
   max-width: 300px;
   display: flex;
   align-items: flex-start;
   justify-content: space-between;
   flex-direction: column;
   gap:1rem;
}

.brand-world-lead-magnets{
  min-height: 598px;
  max-width: 413px;
  margin-top: 40px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap:2rem;
}


.brand-world-circles-container {
	position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 20rem;
  z-index: 1;
}

.mainCircle,
.secondaryCircle,
.thirdCircle {
	position: absolute;
	top: 0;
	left: 10;
}

.mainCircle {
	animation: floatUpDownMain 4s ease-in-out infinite;
	z-index: 1; 
}

.secondaryCircle {
	animation: floatUpDown 4s ease-in-out infinite;
	animation-delay: 1s; 
	z-index: 2; 
}

.thirdCircle {
	animation: floatLeftRight 6s ease-in-out infinite;
	z-index: 3; 
}

.brand-world-bikes-container{
  border-radius: 16px;
  position: relative;
  z-index: 99;
  margin-top: -27rem;
  background-color: #fff;
}

.with-and-without-brand{
  position: relative;
  display: flex;
  gap:4rem;
  margin-bottom: 160px;
  align-items: center;
  justify-content: space-evenly;
  min-height: 20rem;
  margin-top: 200px;
  z-index: 5;
}

 .background-CirclesContainer {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 0;
}

.background-mainCircle,
.background-secondaryCircle,
.background-thirdCircle {
	position: absolute;
  width: 670px;
	height: 823px;
}

.background-mainCircle {
	animation: floatUpDown 4s ease-in-out infinite;
	top: -35%;
	left: 0%;
	transform: translate(-50%, -50%);
}

.background-secondaryCircle {
	animation: floatUpDown 4s ease-in-out infinite;
	animation-delay: 2s;
	top: -35%;
	left: 0%;
	transform: translate(-50%, -50%);
}

.background-thirdCircle {
	animation: floatLeftRight 6s ease-in-out infinite;
	top: -35%;
	left: 0%;
	transform: translate(-50%, -50%);
}

.fact-box{
//  width: 552px;
  min-height: 307px;
  padding: 24px 16px;
  border-radius: 32px;
  background-color: #434446;
  flex-shrink: 0;
}

.brandworld-service-center-box{
  display: flex;
  margin-top: 120px;
  align-items: center;
  justify-content: center;
  padding: 80px 0px 80px  0px ;
  border-radius: 32px;
  background-color: #434446;
}
.brand-header-section-container{
    z-index: 1;
    background-color: #212529;
    overflow: hidden;
    border-bottom-left-radius: 50% 20%;
    border-bottom-right-radius: 50% 20%;
    padding-bottom: 250px;
  
}
.brand-info-section-container {
  z-index: 1;
  margin-top: -28rem;
  background-color: #212529;
  overflow: hidden;
  border-top-left-radius: 60% 20%;
  border-top-right-radius: 60% 20%;
  padding-top: 500px;
  border-bottom-left-radius: 32px;
  border-bottom-right-radius: 32px;
  padding-bottom: 100px;
}
.brandworld-typography-title,
.brandworld-typography-subtitle,
.brand-fact-typography-subtitle,
.typography-description {
  color: #fff;
  font-size: 16px !important;
  line-height: 24px !important;
}

.brandworld-typography-title {
  font-size: 20px !important;
  line-height: 32px !important;
}

.brandworld-typography-subtitle,
.brand-fact-typography-subtitle {
  opacity: 0.6;
}

.typography-amount {
  font-size: 64px !important;
  line-height: 76.8px !important;
  z-index: 20;
}

.typography-description {
  text-align: center;
  opacity: 0.6;
  max-width: 480px;
}
.brandworld-contactus-container{
  display: fl
}

#hubspot-messages-iframe-container.widget-align-right {
  margin-bottom: 75px;
  z-index: 10000009;
}

.bottom-sticky-bar{
    position: fixed !important;
    bottom: 0;
    opacity: 0;
    width: 100%;
    transform: translateY(100%);
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
    z-index: 99999999;
    border-radius: 32px 32px 0px 0px;
    padding: 12px 0px;
    background-color: #F8F8F8;
    box-shadow: 0px -4px 12px 0px rgba(0,0,0,0.08);
}

.bottom-sticky-bar.slide-up {
    transform: translateY(0);
    opacity: 1;
}
  
.bottom-sticky-bar.slide-down {
    transform: translateY(100%);
    opacity: 0;
}

.lead-magnet-card{
    transition: scale 0.3s ease;
    &:hover{
        scale: 1.05;
    }
}

.leadmagnet-list-item:active {
    background-color: #DCE1E7;
    border-radius: 4px; 
}

// File for Media Queries
@import "./media-queries";

